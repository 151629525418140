// src/views/admin/default/components/TotalSpent.js

// Importações do Chakra UI
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

// Componentes personalizados
import Card from "./Card";
import React, { useState, useEffect, useCallback } from "react";
import PixPayment from "./PixPayment"; // Importando o componente PixPayment

export default function TotalSpent(props) {
  // Configurações de cores do Chakra UI
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  // Estado para controlar a abertura do modal do PixPayment
  const [isPixModalOpen, setIsPixModalOpen] = useState(false);

  // Estados para armazenar os dados da API
  const [availableConsultations, setAvailableConsultations] = useState(0);
  const [totalConsultations, setTotalConsultations] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Função para abrir o modal do PixPayment
  const handleAddCreditsClick = () => {
    setIsPixModalOpen(true);
  };

  // Função para fechar o modal do PixPayment
  const handleClosePixModal = () => {
    setIsPixModalOpen(false);
  };

  // Função para buscar os dados da API
  const fetchConsultationData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("Token de autenticação não encontrado");

      const response = await fetch(
        "https://querubimcompany.com/consultations/consultation-status-today",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.message || "Erro ao obter dados das consultas"
        );
      }

      // Usando availableCreditsToday como total de consultas disponíveis
      const { consultationsToday, availableCreditsToday } = data.data;

      // Total de consultas realizadas hoje
      const totalConsultationsDone =
        consultationsToday.cpf +
        consultationsToday.cnpj +
        consultationsToday.vehicle;

      setAvailableConsultations(availableCreditsToday);
      setTotalConsultations(totalConsultationsDone);
    } catch (error) {
      setError(error.message);
      console.error("Erro ao obter dados das consultas:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // useEffect para buscar os dados da API quando o componente for montado
  useEffect(() => {
    fetchConsultationData();
  }, [fetchConsultationData]);

  // useEffect para atualizar os dados quando uma consulta é realizada
  useEffect(() => {
    const handleConsultationMade = () => {
      fetchConsultationData();
    };

    window.addEventListener("consultationMade", handleConsultationMade);

    return () => {
      window.removeEventListener("consultationMade", handleConsultationMade);
    };
  }, [fetchConsultationData]);

  return (
    <>
      <Box
        p="24px"
        bg={boxBg}
        borderRadius="16px"
        boxShadow="xl"
        borderWidth="1px"
        borderColor={borderColor}
        w="100%"
        {...props}
      >
        {/* Exibe o Spinner ou a mensagem de erro se estiver carregando ou ocorreu um erro */}
        {loading ? (
          <Flex justify="center" align="center" h="150px">
            <Spinner size="lg" />
          </Flex>
        ) : error ? (
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            {error}
          </Alert>
        ) : (
          // Se os dados foram carregados com sucesso, exibe os cards
          <Flex
            justify="space-between"
            direction="row" // Mantém os itens lado a lado em todos os tamanhos de tela
            align="center"
            w="100%"
            gap="24px"
          >
            {/* Card 1 - Consultas Disponíveis com Botão para Adicionar Créditos */}
            <Card
              justifyContent="center"
              align="center"
              direction="column"
              p="24px"
              borderRadius="20px"
              boxShadow="md"
              bg={boxBg}
              w="48%" // 48% de largura para manter lado a lado em todos os tamanhos de tela
              position="relative" // Para posicionar o botão de adicionar créditos
            >
              <Text
                color={textColor}
                fontSize="34px"
                fontWeight="700"
                textAlign="center"
              >
                {availableConsultations}
              </Text>
              <Text
                color="secondaryGray.600"
                fontSize="sm"
                fontWeight="500"
                mt="4px"
                textAlign="center"
              >
                Consultas Disponíveis
              </Text>

              {/* Botão para adicionar créditos */}
              <IconButton
                icon={<AddIcon />}
                colorScheme="green"
                borderRadius="full"
                size="sm"
                position="absolute"
                display={{ base: "block", md: "none" }} // Mostrar no topo apenas para mobile
                top="12px"
                right="12px"
                aria-label="Adicionar mais créditos"
                onClick={handleAddCreditsClick}
              />
              <IconButton
                icon={<AddIcon />}
                colorScheme="green"
                borderRadius="full"
                size="sm"
                position="absolute"
                display={{ base: "none", md: "block" }} // Mostrar embaixo para telas maiores
                bottom="12px"
                right="12px"
                aria-label="Adicionar mais créditos"
                onClick={handleAddCreditsClick}
              />
            </Card>

            {/* Card 2 - Consultas Realizadas Hoje */}
            <Card
              justifyContent="center"
              align="center"
              direction="column"
              p="24px"
              borderRadius="20px"
              boxShadow="md"
              bg={boxBg}
              w="48%" // 48% de largura para manter lado a lado em todos os tamanhos de tela
              position="relative"
            >
              <Text
                color={textColor}
                fontSize="34px"
                fontWeight="700"
                textAlign="center"
              >
                {totalConsultations}
              </Text>
              <Text
                color="secondaryGray.600"
                fontSize="sm"
                fontWeight="500"
                mt="4px"
                textAlign="center"
              >
                Consultas Realizadas
              </Text>
            </Card>
          </Flex>
        )}
      </Box>

      {/* Renderiza o PixPayment somente se o modal estiver aberto */}
      {isPixModalOpen && (
        <PixPayment
          isOpen={isPixModalOpen}
          onClose={handleClosePixModal}
          onPaymentSuccess={fetchConsultationData} // Passa a função para atualizar os dados
        />
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  VStack,
  useColorModeValue,
  Spinner,
  useToast,
  Grid,
  Badge,
} from '@chakra-ui/react';
import { MdDownload } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const LastConsultation = ({ token, refresh }) => {
  const [consultations, setConsultations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();

  // Chakra Color Mode
  const textColor = useColorModeValue('gray.800', 'white');
  const boxBg = useColorModeValue('white', 'gray.700');
  const buttonBg = useColorModeValue('blue.400', 'blue.600');
  const buttonHoverBg = useColorModeValue('blue.500', 'blue.700');
  const buttonFocus = useColorModeValue('blue.300', 'blue.500');
  const minimalBorder = useColorModeValue('1px solid #E2E8F0', '1px solid #2D3748');

  const getFriendlyTypeName = (type) => {
    switch (type.toLowerCase()) {
      case 'cpf':
        return 'Consulta de CPF';
      case 'cnpj':
        return 'Consulta de CNPJ';
      case 'telefone':
        return 'Consulta de Telefone';
      case 'email':
        return 'Consulta de E-mail';
      case 'obito':
        return 'Consulta de Óbito';
      case 'vehicle':
        return 'Consulta de Veículo';
      default:
        return 'Consulta';
    }
  };

  const generateProtocol = (id) => {
    return `PRT-${id.toString().padStart(6, '0')}`;
  };

  const fetchLastConsultations = async () => {
    if (!token) {
      console.error('Token não disponível.');
      setError('Token não disponível.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await fetch('https://querubimcompany.com/consultations/last-consultations', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 403) {
        setError('Acesso negado. Por favor, verifique suas permissões.');
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro ao buscar as últimas consultas.');
      }

      const data = await response.json();
      if (data.status === 200 && data.data && Array.isArray(data.data.data)) {
        setConsultations(data.data.data);
      } else {
        throw new Error('Estrutura de dados inesperada da API.');
      }
    } catch (error) {
      setError(error.message || 'Erro desconhecido ao buscar as últimas consultas.');
      toast({
        title: 'Erro',
        description: error.message || 'Erro ao buscar as últimas consultas.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const generatePdf = (consultation) => {
    const doc = new jsPDF('portrait', 'mm', 'a4');

    // Cabeçalho do PDF
    doc.setFontSize(22);
    doc.setFont('helvetica', 'bold');
    doc.text('Relatório de Consulta', 14, 20);
    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');
    doc.text(`Protocolo: ${generateProtocol(consultation.consultation.id)}`, 14, 30);
    doc.text(`Tipo de Consulta: ${getFriendlyTypeName(consultation.type)}`, 14, 40);
    doc.text(`Data de Criação: ${format(parseISO(consultation.consultation.createdAt), 'dd/MM/yyyy HH:mm')}`, 14, 50);

    // Seção de Detalhes da Consulta
    const formattedData = formatConsultationDetails(consultation.consultation);

    // Ajuste na largura das colunas para melhor visualização
    const columnStyles = {
      0: { cellWidth: 70 }, // Coluna "Campo"
      1: { cellWidth: 120 } // Coluna "Valor"
    };

    // Tabela de Dados
    doc.autoTable({
      startY: 60,
      head: [['Campo', 'Valor']],
      body: formattedData,
      theme: 'grid',
      headStyles: {
        fillColor: [230, 230, 230],
        textColor: 0,
        fontStyle: 'bold',
      },
      bodyStyles: {
        cellPadding: 5,
        fontSize: 12,
        textColor: [0, 0, 0],
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245],
      },
      columnStyles: columnStyles,
      margin: { top: 60, left: 14, right: 14 },
    });

    // Salva o PDF
    doc.save(`consulta_${consultation.type}_${consultation.consultation.id}.pdf`);
  };

  const formatKey = (key) => {
    const dictionary = {
      'created_at': 'Data de Criação',
      'updated_at': 'Data de Atualização',
      'documento': 'Documento',
      'query': 'Parâmetro Consultado',
      'nome': 'Nome',
      'cpf': 'CPF',
      'data_nascimento': 'Data de Nascimento',
      'rg': 'RG',
      'mae': 'Mãe',
      'pai': 'Pai',
      'sexo': 'Sexo',
      'nis': 'NIS',
      'pis': 'PIS',
      'signo': 'Signo',
      'renda': 'Renda',
      'profissao': 'Profissão',
      'logradouro': 'Logradouro',
      'numero': 'Número',
      'complemento': 'Complemento',
      'bairro': 'Bairro',
      'cidade': 'Cidade',
      'uf': 'UF',
      'cep': 'CEP',
      'ddd': 'DDD',
      'numero_telefone': 'Número de Telefone',
      'classificacao': 'Classificação',
      'whatsapp': 'WhatsApp',
      'email': 'E-mail',
      'empresa': 'Empresa',
      'capital_social': 'Capital Social',
      'porte_empresa': 'Porte da Empresa',
      'cnpj': 'CNPJ',
    };

    return dictionary[key.toLowerCase()] || key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const formatValue = (value) => {
    if (typeof value === 'object') {
      return JSON.stringify(value, null, 2)
        .replace(/[{}"]/g, '')
        .replace(/,/g, '\n')
        .replace(/\n\s+/g, '\n')
        .trim();
    } else if (Array.isArray(value)) {
      return value.map(formatValue).join(', ');
    } else {
      return value ? value.toString().replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()) : 'Nenhum dado encontrado';
    }
  };

  const formatConsultationDetails = (consultation) => {
    return Object.entries(consultation)
      .filter(([_, value]) => value !== null && value !== undefined && value !== '')
      .flatMap(([key, value]) => {
        if (typeof value === 'object' && !Array.isArray(value)) {
          return Object.entries(value)
            .filter(([_, subValue]) => subValue !== null && subValue !== undefined && subValue !== '')
            .map(([subKey, subValue]) => [
              `${formatKey(key)} - ${formatKey(subKey)}`,
              formatValue(subValue)
            ]);
        } else if (Array.isArray(value)) {
          return value
            .filter((item) => item !== null && item !== undefined && item !== '')
            .flatMap((item, index) => {
              if (typeof item === 'object') {
                return Object.entries(item)
                  .filter(([_, subValue]) => subValue !== null && subValue !== undefined && subValue !== '')
                  .map(([subKey, subValue]) => [
                    `${formatKey(key)} [${index + 1}] - ${formatKey(subKey)}`,
                    formatValue(subValue)
                  ]);
              } else {
                return [[`${formatKey(key)} [${index + 1}]`, formatValue(item)]];
              }
            });
        } else {
          return [[formatKey(key), formatValue(value)]];
        }
      });
  };

  useEffect(() => {
    fetchLastConsultations();
  }, [token, refresh]);

  return (
    <Box p="13px" bg={boxBg} borderRadius="16px" boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)">
      <Flex justify="space-between" align="center" mb="20px">
        <Text color={textColor} fontSize="2xl" fontWeight="bold">
          Últimas Consultas
        </Text>
      </Flex>

      {loading ? (
        <Flex justify="center" align="center" height="100px">
          <Spinner size="lg" color="blue.500" />
        </Flex>
      ) : error ? (
        <Text color="red.500" fontSize="md">
          {error}
        </Text>
      ) : consultations.length > 0 ? (
        <VStack spacing="16px" align="stretch">
          {consultations.map((item) => (
            <Box
              key={item.consultation.id}
              p="13px"
              borderRadius="12px"
              bg={boxBg}
              border={minimalBorder}
              boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
              transition="all 0.3s ease"
              _hover={{
                transform: 'scale(1.01)',
                borderColor: 'blue.400',
              }}
            >
              <Grid templateColumns="1fr auto" gap={4} alignItems="center">
                <Box>
                  <Flex align="center">
                    <Text fontSize="lg" fontWeight="600" mr="8px">
                      🕵🏼‍♂️
                    </Text>
                    <Text fontSize="lg" fontWeight="600">
                      {getFriendlyTypeName(item.type)} #{item.consultation.id}
                    </Text>
                  </Flex>
                  <Text fontSize="sm" color="gray.500" mt={1}>
                    Parâmetro Consultado: {item.consultation.query || item.consultation.documento}
                  </Text>
                  <Badge colorScheme="green" mt={2}>
                    Protocolo: {generateProtocol(item.consultation.id)}
                  </Badge>
                </Box>

                <Button
                  leftIcon={<Icon as={MdDownload} color="white" w="20px" h="20px" />}
                  bg={buttonBg}
                  color="white"
                  _hover={{ bg: buttonHoverBg }}
                  _focus={{ bg: buttonFocus }}
                  size="sm"
                  borderRadius="8px"
                  maxW={{ base: '120px', md: '140px' }}
                  whiteSpace="nowrap"
                  onClick={() => generatePdf(item)}
                >
                  Baixar PDF
                </Button>
              </Grid>
            </Box>
          ))}
        </VStack>
      ) : (
        <Text color={textColor} fontSize="lg" fontWeight="600">
          Nenhuma consulta recente disponível.
        </Text>
      )}
    </Box>
  );
};

export default LastConsultation;

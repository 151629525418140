import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  useColorModeValue,
  VStack,
  useToast,
} from '@chakra-ui/react';
import Popup from './Popup';
import PaymentPopup from './PaymentPopup';
import LastConsultation from './LastConsultation';

const SelectAndInput = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isPaymentPopupOpen, setPaymentPopupOpen] = useState(false);
  const [consultationCount, setConsultationCount] = useState(0);

  // Cores neutras até o elemento ser selecionado ou focado
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const buttonBg = useColorModeValue('blue.400', 'blue.600');
  const buttonHoverBg = useColorModeValue('blue.500', 'blue.700');
  
  // Aqui você pode definir o tom específico de azul que deseja
  const focusBorderColor = "#3182CE"; // Defina o tom de azul específico que você quer

  const toast = useToast();

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      toast({
        title: 'Token não encontrado.',
        description: 'Usuário não autenticado.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  const handleQuery = async () => {
    if (!inputValue) {
      toast({
        title: 'Entrada necessária.',
        description: 'Por favor, digite um valor antes de consultar.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!token) {
      toast({
        title: 'Usuário não autenticado.',
        description: 'Por favor, faça login para continuar.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    let apiUrl = '';
    let requestBody = {};

    switch (selectedValue) {
      case 'cpf':
        apiUrl = `https://querubimcompany.com/consultations/cpf`;
        requestBody = { cpf: inputValue };
        break;
      case 'cnpj':
        apiUrl = `https://querubimcompany.com/consultations/cnpj`;
        requestBody = { cnpj: inputValue };
        break;
      case 'telefone':
        apiUrl = `https://querubimcompany.com/consultations/phone`;
        requestBody = { phone: inputValue };
        break;
      case 'email':
        apiUrl = `https://querubimcompany.com/consultations/email`;
        requestBody = { email: inputValue };
        break;
      case 'obito':
        apiUrl = `https://querubimcompany.com/consultations/obito`;
        requestBody = { obito: inputValue };
        break;
      case 'placa':
        apiUrl = `https://querubimcompany.com/consultations/vehicle`;
        requestBody = { placa: inputValue };
        break;

      default:
        toast({
          title: 'Seleção inválida.',
          description: 'Por favor, selecione uma opção válida.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        return;
    }

    try {
      setLoading(true);

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 403) {
        setPaymentPopupOpen(true);
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Erro na consulta: ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Resultado da consulta:', result);

      setPopupData(result?.data || { error: 'Nenhum dado encontrado.' });
      setPopupOpen(true);

      setConsultationCount((prevCount) => prevCount + 1);

      setSelectedValue('');
      setInputValue('');

      window.dispatchEvent(new Event('consultationMade'));
    } catch (error) {
      console.error('Erro ao realizar consulta:', error);
      setPopupData({ error: `Erro ao realizar consulta: ${error.message || 'Erro desconhecido'}` });
      setPopupOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        p="24px"
        bg={bgColor} // Cor neutra de fundo
        borderRadius="16px"
        boxShadow="xl"
        borderWidth="1px"
        borderColor={borderColor} // Borda neutra
        mt="16px"
      >
        <VStack spacing="24px" align="stretch">
          <FormControl>
            <FormLabel htmlFor="select" color={textColor}>
              Selecione uma opção e faça uma consulta
            </FormLabel>
            <Select
              id="select"
              placeholder="Selecione uma opção"
              onChange={(e) => setSelectedValue(e.target.value)}
              value={selectedValue || ''}
              bg="white" // Cor de fundo neutra para o select
              borderColor={borderColor} // Borda neutra até ser focado
              borderWidth="2px" // Garantindo espessura consistente
              focusBorderColor={focusBorderColor} // Cor de azul específica ao ser focado/selecionado
              size="lg"
            >
              <option value="cpf">Consultar CPF</option>
              <option value="cnpj">Consultar CNPJ</option>
              <option value="telefone">Consultar Telefone</option>
              <option value="email">Consultar Email</option>
              <option value="obito">Consultar Óbito</option>
              <option value="placa">Consultar Placa</option>
            </Select>
          </FormControl>

          {selectedValue && (
            <FormControl>
              <FormLabel htmlFor="input" color={textColor}>
                Digite os dados
              </FormLabel>
              <Input
                id="input"
                placeholder="Digite aqui"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                bg="white" // Cor de fundo neutra para o input
                borderColor={borderColor} // Borda neutra até ser focada
                borderWidth="2px" // Garantindo espessura consistente
                focusBorderColor={focusBorderColor} // Cor de azul específica ao ser focado/selecionado
                size="lg"
              />
            </FormControl>
          )}

          <Button
            bg={buttonBg}
            color="white"
            _hover={{ bg: buttonHoverBg }}
            onClick={handleQuery}
            isLoading={loading}
            size="lg"
            isDisabled={loading}
          >
            Consultar
          </Button>
        </VStack>
      </Box>

      {token && (
        <Box mt="10px">
          <LastConsultation
            token={token}
            refresh={consultationCount}
          />
        </Box>
      )}

      <Popup isOpen={isPopupOpen} onClose={() => setPopupOpen(false)} data={popupData} />

      <PaymentPopup isOpen={isPaymentPopupOpen} onClose={() => setPaymentPopupOpen(false)} />
    </>
  );
};

export default SelectAndInput;

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  VStack,
  Text,
  Heading,
  Spinner,
  Alert,
  AlertIcon,
  Input,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  CloseButton,
  Box,
  useToast,
  Icon,
} from '@chakra-ui/react';

import { AddIcon, MinusIcon, CheckIcon } from '@chakra-ui/icons';
import { QRCodeCanvas } from 'qrcode.react';
import PlansPopup from './PlansPopup'; // Importa o componente PlansPopup
import { useDisclosure } from '@chakra-ui/react'; // Importa useDisclosure para controle do modal

import { io } from 'socket.io-client'; // Importa o socket.io-client

const PixPayment = ({ isOpen, onClose }) => {
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [copiaCola, setCopiaCola] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const toast = useToast();

  // Controle para o PlansPopup
  const { isOpen: isPlansOpen, onOpen: onPlansOpen, onClose: onPlansClose } = useDisclosure();

  // useEffect para configurar o Socket.IO
  useEffect(() => {
    let socket;
    if (isConfirmed && !paymentConfirmed) {
      // Conectar ao WebSocket no backend
      socket = io('https://querubimcompany.com'); // Substitua pelo seu URL de produção

      socket.on('connect', () => {
        console.log('Conectado ao WebSocket do servidor!');
      });

      // Ouvir o evento 'paymentReceived'
      socket.on('paymentReceived', (data) => {
        if (data.status === 'approved') {
          setPaymentConfirmed(true);
          // Exibir um alerta ou toast se desejar
          // alert(`Pagamento recebido com sucesso! ID do Pagamento: ${data.paymentId}`);
        }
      });

      // Limpar a conexão quando o componente for desmontado ou quando isConfirmed/paymentConfirmed mudar
      return () => {
        if (socket) {
          socket.disconnect();
          console.log('Socket desconectado');
        }
      };
    }
  }, [isConfirmed, paymentConfirmed]);

  // useEffect para tratar o pagamento confirmado
  useEffect(() => {
    if (paymentConfirmed) {
      // Exibe um toast de sucesso com duração de 3 segundos
      toast({
        title: 'Pagamento confirmado!',
        description: 'Seus créditos foram adicionados com sucesso.',
        status: 'success',
        duration: 3000, // Duração do toast em milissegundos
        isClosable: true,
      });

      // Fecha o modal e recarrega a página após 4 segundos
      setTimeout(() => {
        handleClose();
        window.location.reload(); // Recarrega a página
      }, 4000);
    }
  }, [paymentConfirmed]);

  const calculateTotal = () => {
    const pricePerCredit = quantity >= 5 ? 9.9 : 19.9;
    return (pricePerCredit * quantity).toFixed(2);
  };

  const calculateWithoutDiscount = () => {
    return (19.9 * quantity).toFixed(2);
  };

  const generatePixPayment = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('authToken');
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch('https://querubimcompany.com/pagamentos/pix', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ quantity }),
      });

      const data = await response.json();

      console.log('Resposta da API:', data); // Para depuração

      if (!response.ok) {
        throw new Error(`Erro: ${data.message || 'Erro ao gerar QR code de pagamento via Pix'}`);
      }

      if (!data.payment || !data.payment.qrCode) {
        throw new Error('QR Code não encontrado na resposta da API.');
      }

      setQrCodeUrl(data.payment.qrCode);
      // Ajuste aqui: defina o copiaCola corretamente
      setCopiaCola(data.payment.qrCode); // Se o copiaCola for o mesmo que qrCode
      // Se o copiaCola vier em data.payment.copiaCola, use:
      // setCopiaCola(data.payment.copiaCola);
    } catch (error) {
      setError(`Erro ao gerar QR code de pagamento: ${error.message}`);
      console.error('Erro ao gerar pagamento via Pix:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmQuantity = () => {
    if (quantity > 0) {
      setIsConfirmed(true);
      generatePixPayment();
    } else {
      setError('Por favor, insira uma quantidade válida de créditos.');
    }
  };

  const handleClose = () => {
    setQrCodeUrl(null);
    setCopiaCola('');
    setQuantity(1);
    setIsConfirmed(false);
    setPaymentConfirmed(false);
    setError(null);
    onClose();
  };

  const incrementQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  const decrementQuantity = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value > 0) {
      setQuantity(value);
    } else if (e.target.value === '') {
      setQuantity('');
    } else {
      setQuantity(1);
    }
  };

  const handleCopyPix = () => {
    navigator.clipboard.writeText(copiaCola).then(() => {
      toast({
        title: 'Código Pix copiado!',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      });
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent
          bg="white"
          borderRadius="lg"
          boxShadow="2xl"
          p={6}
          maxW={{ base: '90%', md: '500px' }}
          mx="auto"
          minH="450px"
        >
          <CloseButton
            position="absolute"
            top="10px"
            right="10px"
            size="lg"
            onClick={handleClose}
            color="gray.600"
          />

          <ModalHeader textAlign="center" mb={4}>
            <Heading size="lg" color="gray.800">
              Comprar Créditos via Pix
            </Heading>
          </ModalHeader>

          <ModalBody
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            height="100%"
          >
            <VStack spacing="24px" textAlign="center" justifyContent="center" alignItems="center" w="100%">
              {error && (
                <Alert status="error" borderRadius="md">
                  <AlertIcon />
                  {error}
                </Alert>
              )}

              {!isConfirmed ? (
                <>
                  <Text fontSize={{ base: 'sm', md: 'md' }} color="green.600" fontWeight="bold" maxW="80%">
                    Ao comprar 5 créditos ou mais, o valor de cada crédito é reduzido para R$ 9,90.
                  </Text>

                  <FormControl>
                    <FormLabel
                      htmlFor="quantity"
                      fontSize={{ base: 'lg', md: '2xl' }}
                      fontWeight="bold"
                      textAlign="center" // Centraliza o texto
                    >
                      Quantidade de Créditos
                    </FormLabel>
                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                      <HStack spacing={6}>
                        <IconButton
                          icon={<MinusIcon />}
                          onClick={decrementQuantity}
                          aria-label="Diminuir quantidade"
                          size="lg"
                          colorScheme="red"
                          bg="red.400"
                          color="white"
                          _hover={{ bg: 'red.500' }}
                          borderRadius="full"
                          fontSize="2xl"
                        />
                        <Input
                          id="quantity"
                          type="number"
                          value={quantity}
                          onChange={handleQuantityChange}
                          textAlign="center"
                          w="100px"
                          h="60px"
                          fontSize="2xl"
                          borderRadius="md"
                          borderWidth="1px"
                          borderColor="gray.300"
                        />
                        <IconButton
                          icon={<AddIcon />}
                          onClick={incrementQuantity}
                          aria-label="Aumentar quantidade"
                          size="lg"
                          colorScheme="green"
                          bg="green.400"
                          color="white"
                          _hover={{ bg: 'green.500' }}
                          borderRadius="full"
                          fontSize="2xl"
                        />
                      </HStack>
                    </Box>
                  </FormControl>

                  {quantity >= 5 && (
                    <Text fontSize="sm" color="gray.700" textDecoration="line-through" fontWeight="bold">
                      Valor sem desconto: R$ {calculateWithoutDiscount()}
                    </Text>
                  )}

                  <Text fontSize="lg" color={quantity >= 5 ? 'green.600' : 'gray.700'} fontWeight="bold">
                    Total a pagar: R$ {calculateTotal()}
                  </Text>

                  <Button
                    onClick={handleConfirmQuantity}
                    colorScheme="blue"
                    px="6"
                    py="4"
                    fontSize={{ base: 'md', md: 'lg' }}
                    fontWeight="bold"
                    _hover={{ bg: 'blue.600' }}
                    borderRadius="md"
                    mt={6}
                    w="full"
                  >
                    Confirmar Quantidade
                  </Button>

                  {/* Texto com link para abrir o PlansPopup */}
                  <Text fontSize="sm" color="blue.500" mt={2} cursor="pointer" onClick={onPlansOpen}>
                    Prefere assinar um plano? Clique aqui.
                  </Text>
                </>
              ) : paymentConfirmed ? (
                <Box textAlign="center">
                  <Icon as={CheckIcon} boxSize={16} color="green.500" />
                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="green.600" fontWeight="bold" mt={4}>
                    Pagamento confirmado!
                  </Text>
                </Box>
              ) : (
                <>
                  <Text fontSize="md" color="gray.600">
                    Escaneie o QR Code ou copie o código Pix para pagar.
                  </Text>

                  {loading ? (
                    <Spinner size="xl" />
                  ) : (
                    qrCodeUrl && (
                      <>
                        <QRCodeCanvas value={qrCodeUrl} size={200} level="H" includeMargin={true} />

                        <Text color="gray.500" fontSize="sm" mt={3}>
                          Código para "Copia e Cola":
                        </Text>
                        <Box
                          onClick={handleCopyPix}
                          cursor="pointer"
                          bg="gray.100"
                          p={2}
                          borderRadius="md"
                          _hover={{ bg: 'gray.200' }}
                        >
                          <Text
                            fontSize="md"
                            color="blue.600"
                            wordBreak="break-all"
                            textAlign="center"
                          >
                            {copiaCola}
                          </Text>
                        </Box>
                        {/* Adicionando a mensagem solicitada */}
                        <Text fontSize="sm" color="gray.500">
                          Clique para copiar o PIX Copia e Cola
                        </Text>
                      </>
                    )
                  )}
                </>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Renderiza o PlansPopup somente se estiver aberto */}
      {isPlansOpen && <PlansPopup isOpen={isPlansOpen} onClose={onPlansClose} />}
    </>
  );
};

export default PixPayment;
